import {PortalHost, PortalProvider} from "conjointment"
import {createRoot} from "react-dom/client"
import {Container as FlashNotifications} from "flash-notifications"
import {LoadingBlocker} from "components/layout/loading-blocker"
import ModelsResponseReader from "@kaspernj/api-maker/src/models-response-reader"
import Router from "@kaspernj/api-maker/src/router"
import SessionStatusUpdater from "@kaspernj/api-maker/src/session-status-updater"
import withPathname from "on-location-changed/src/with-path"

const LayoutNotFoundPage = React.lazy(() => import(/* webpackChunkName: "not-found-page" */ "components/layout/not-found-page"))
const routeDefinitions = require("shared/route-definitions-play.json")

require("shared/devise")

if (window.currentUserData) {
  const modelsResponseReader = new ModelsResponseReader({response: window.currentUserData})
  const models = modelsResponseReader.models()

  window.currentUser = models[0]
}

window.sessionStatusUpdater = new SessionStatusUpdater()
sessionStatusUpdater.startTimeout()

const requireComponent = ({routeDefinition}) => React.lazy(() => import(/* webpackChunkName: "[request]" */ `routes/play/${routeDefinition.component}-route`))

document.addEventListener("DOMContentLoaded", () => {
  const reactRoot = document.querySelector(".react-root")
  const root = createRoot(reactRoot)
  const RouterWithLocation = withPathname(Router)

  root.render(
    <PortalProvider>
      <PortalHost>
        <LoadingBlocker />
        <FlashNotifications />
        <RouterWithLocation
          history={AppHistory}
          notFoundComponent={LayoutNotFoundPage}
          requireComponent={requireComponent}
          routeDefinitions={routeDefinitions}
          routes={RoutesPlay}
        />
      </PortalHost>
    </PortalProvider>
  )
})
